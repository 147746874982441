import React from "react";
import { useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import marketing from '../../../../services/marketing';

function MoneyGram() {
    const setting = useStoreState((state) => state.siteSettingsModel);
    const applicationResult = useStoreState((state) => state.applicationResultModel.applicationResult);
    const utms = new marketing();
    const WaterfallEvents = { "ACTIVATION_PAYMENT": 33 };
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const Flow = useStoreState(state => state.siteSettingsModel.mainSettings.Flow);
    const MainPath = useStoreState((state) => state.siteSettingsModel.MainPath);
    const history = useHistory();
    const ProductName = setting.brandSettings.ProductName === "Fit" ? setting.brandSettings.ProductName.toUpperCase() : setting.brandSettings.ProductName;

    return (
        <div className="row card-box m-0">
            <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <img src='/personal-payment.png' style={{ width: "90%" }} alt='peronal payment' className='mb-2' />
                <p>
                    Please be advised that MoneyGram may charge you a fee for making this payment.
                </p>
            </div>
            <div className='col-lg-8 col-md-8 col-sm-12 col-xs-12 mb-2'>
                <p className='px-0'>
                    Locate the nearest MoneyGram location by visiting www.moneygram.com.
                </p>
                <p className='px-0'>
                    When you arrive say you are making a payment for the {ProductName} Mastercard and give them
                    the following:
                </p>
                <p className='px-0 font-weight-bold'>
                    Receive Code 16471. <br></br>City: Newark. <br></br>State: DE.
                </p>
                <p className='px-0'>
                    Please make sure to include your Reference Number on your MoneyGram payment.
                </p>
            </div>
            <div className='col-12 d-flex flex-column align-items-center'>
                <hr className='w-100' />
                <p className="font-italic font-weight-bold">I'm making my payment via MoneyGram</p>
                <button
                    variant="primary"
                    className="pay-processing-button align-items-center"
                    style={{ backgroundColor: setting.brandSettings.BannerBgColor, border: "1px solid transparent" }}
                    onClick={() => { 
                        sessionStorage.setItem("Payment", 0); 
                        history.push(MainPath + "enjoy"); 
                        utms.trackEvent(WaterfallEvents[Flow] || 0, Flow, applicationResult.ref_number, productSettings.ProductId, "Flow");
                    }}
                >
                    Select
                </button>
            </div>
        </div>
    );
}

export default MoneyGram;