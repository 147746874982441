import React from "react";
import { useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import marketing from '../../../../services/marketing';

function Phone() {
    const setting = useStoreState((state) => state.siteSettingsModel);
    const applicant = useStoreState((state) => state.applicantModel.applicant);
    const applicationResult = useStoreState((state) => state.applicationResultModel.applicationResult);
    const utms = new marketing()
    const WaterfallEvents = { "ACTIVATION_PAYMENT": 35 };
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const Flow = useStoreState(state => state.siteSettingsModel.mainSettings.Flow);
    const MainPath = useStoreState((state) => state.siteSettingsModel.MainPath);
    const history = useHistory();

    return (
        <div className="row card-box m-0">
            <div className="col-12">
                <div><strong>Approved Applicant:</strong> {applicant.FirstName || "Test"} {applicant.LastName || "Test"}</div>
                <div><strong>Reference Number:</strong> {applicationResult.ref_number || "16212152"}</div>
                <p className="pr-0 pl-0">
                    In order to activate and use your new credit card when it arrives, you will need to submit payment of your $95.00 processing fee.
                </p>
                <p className="pr-0 pl-0">
                    Your account can not be opened or used until this payment is complete.
                </p>
                <p className="pr-0 pl-0">
                    If you would prefer to pay this fee over the phone, please call us at {" "}
                    <span className="mobile-link"><a href="tel:1-888-525-3038">1-888-525-3038</a></span>
                    <span className="desktop-number">1-888-525-3038</span>.
                </p>
            </div>
            <div className="col-12 d-flex flex-column align-items-center">
                <hr className='w-100' />
                <p className="font-italic font-weight-bold">I'm making my payment over the phone</p>
                <button
                    variant="primary"
                    className="pay-processing-button align-items-center"
                    style={{ backgroundColor: setting.brandSettings.BannerBgColor, border: "1px solid transparent" }}
                    onClick={() => {
                        sessionStorage.setItem("Payment", 0);
                        history.push(MainPath + "enjoy");
                        utms.trackEvent(WaterfallEvents[Flow] || 0, Flow, applicationResult.ref_number, productSettings.ProductId, "Flow");
                    }}
                >
                    Select
                </button>
            </div>
        </div>
    );
}

export default Phone;